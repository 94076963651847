/* eslint-disable react-hooks/exhaustive-deps */
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DownloadIcon from '@mui/icons-material/Download';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { addZero } from '../../../services';
import api from '../../../services/api';
import { PtBr } from '../../../services/pt_br';
import {
  BREAKPOINT_LG,
  BREAKPOINT_MD,
  BREAKPOINT_SM,
  CALENDAR_COLUMN_LG,
  CALENDAR_COLUMN_MD,
  CALENDAR_COLUMN_SM,
  CALENDAR_COLUMN_XL,
} from '../../../styles/globalStyles';
import theme from '../../../theme';
import useWindowSize from '../../../utils/WindowSize';
import CalendarComponent from './components';
import {
  ArrowLeft,
  ArrowRight,
  BodyContainer,
  ButtonsContainer,
  CalendarButton,
  DateInfo,
  IconAreaCalendar,
  InfoDay,
  InfoMonth,
  MenuHeader,
} from './styles';

export default function CalendarScreen() {
  const { width } = useWindowSize();
  const [date, setDate] = useState(moment().toDate());
  const [daysWeek, setDaysWeek] = useState(0);

  useEffect(() => {
    const dayColumn = () => {
      if (width <= BREAKPOINT_SM) return CALENDAR_COLUMN_SM;
      if (width <= BREAKPOINT_MD) return CALENDAR_COLUMN_MD;
      if (width <= BREAKPOINT_LG) return CALENDAR_COLUMN_LG;
      if (width > BREAKPOINT_LG) return CALENDAR_COLUMN_XL;
    };
    setDaysWeek(dayColumn());
  }, [width]);

  useEffect(() => {
    const primaryWeekDay = (days) => {
      let day = moment().format('ddd');
      if (days <= 3) return moment(day, 'ddd').toDate();
      while (day !== 'dom') {
        day = moment(day, 'ddd').subtract(1, 'days').format('ddd');
      }
      return moment(day, 'ddd').toDate();
    };
    setDate(primaryWeekDay(daysWeek));
  }, [daysWeek]);

  const exportCalendar = async () => {
    try {
      const { data: icsContent } = await api.get('/professionals/ical.ics');
      const blob = new Blob([icsContent], { type: 'text/calendar' });

      const url = window.URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      a.download = 'calendar.ics';
      a.style.display = 'none';

      document.body.appendChild(a);
      a.click();

      window.URL.revokeObjectURL(url);
    } catch (error) {
      toast.error('Erro ao exportar agenda');
    }
  };

  const sumSubDate = (days, method) =>
    method === 'add'
      ? moment(date, 'YYYY-MM-DD').add(days, 'days').toDate()
      : moment(date).subtract(days, 'days').toDate();

  const lastDate = sumSubDate(daysWeek - 1, 'add');

  return (
    <BodyContainer>
      <MenuHeader>
        <ButtonsContainer>
          <CalendarButton onClick={() => setDate(sumSubDate(daysWeek, 'sub'))}>
            <ArrowLeft />
          </CalendarButton>

          {daysWeek === 1 ? (
            <DateInfo>
              <InfoMonth>
                {PtBr.months_name[date.getMonth()].toUpperCase()}
              </InfoMonth>
            </DateInfo>
          ) : (
            <DateInfo>
              <InfoMonth>
                {lastDate.getMonth() === date.getMonth()
                  ? PtBr.months_name[date.getMonth()].toUpperCase()
                  : `${PtBr.months_name[
                      date.getMonth()
                    ].toUpperCase()} / ${PtBr.months_name[
                      lastDate.getMonth()
                    ].toUpperCase()}`}
              </InfoMonth>
              <InfoDay>
                {daysWeek === 1
                  ? `${addZero(date.getDate())}`
                  : `${addZero(date.getDate())} a ${addZero(
                      lastDate.getDate()
                    )}`}
              </InfoDay>
            </DateInfo>
          )}

          <CalendarButton onClick={() => setDate(sumSubDate(daysWeek, 'add'))}>
            <ArrowRight />
          </CalendarButton>
        </ButtonsContainer>
      </MenuHeader>
      <CalendarComponent date={date} dayColumn={daysWeek} />
      <IconAreaCalendar onClick={exportCalendar}>
        <DownloadIcon sx={{ color: theme.primary_blue, mr: 1 }} />
        <p style={{ color: theme.primary_blue }}>Exportar Agenda</p>
        <CalendarMonthIcon sx={{ color: theme.primary_blue, ml: 1 }} />
      </IconAreaCalendar>
    </BodyContainer>
  );
}
